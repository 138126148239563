<template>
  <section class="data-list-container">
    <v-card>
      <v-card-text v-dragscroll>
        <v-data-table
            :headers="headers"
            :hide-default-footer="true"
            :items="paginatedItems.data"
            @update:sort-by="updateSortField"
            @update:sort-desc="updateSortType"
        >
          <template v-slot:header="{props}">
            <tr>
              <th v-for="header in props.headers" :key="header.text">
                <template v-if="header.filterable">
                  <filter-input :filter-name="header.value" placeholder="Filter..." @filter="updateFilter"/>
                </template>
              </th>
            </tr>
          </template>

          <template v-slot:item.active="{value}">
            <template v-if="value == 1">
              DA
            </template>
            <template v-else>
              NU
            </template>
          </template>

          <template v-slot:item.actions="{item}">
            <view-button v-if="item.customer_id" :to="{name:'view-client', params:{id:item.customer_id}}"
                         text="Vezi clientul nou"/>
            <activate-button v-else :reseller-id="item.id"/>
            <view-button :to="{name:'old-cit-clients-resellers-show', params:{id: item.id}}"
                         text="Vezi clientul importat"/>
          </template>

        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
        ></v-pagination>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import FilterInput from '@/components/general-form/FilterInput'
import ViewButton from "@/components/general-form/ViewButton";
import ActivateButton from "@/views/ClientsOldResellers/components/ActivateButton";


export default {
  name: 'ResellersList',
  components: {
    ActivateButton,
    ViewButton,
    FilterInput,
  },
  data() {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true,
          width: '50'
        },
        {
          text: 'Activ',
          value: 'active',
          sortable: true,
          width: '100'
        },
        {
          text: 'Nume',
          value: 'name',
          sortable: true,
          filterable: true,
          width: '100'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: true,
          filterable: true,
          width: '100'
        },
        {
          text: 'Telefon',
          value: 'details.phone',
          filterable: true,
        },
        {
          text: 'Nume Firma',
          value: 'details.company_name',
          filterable: true,
          width: '150'
        },
        {
          text: 'CUI',
          value: 'details.company_code',
          filterable: true,
          width: '150'
        },
        {
          text: 'CAEN',
          value: 'details.company_caen',
          width: '150'
        },
        {
          text: 'Adaos Distributie',
          value: 'adaos_distributie',
          sortable: false,
        },
        {
          text: 'Cifra de afaceri',
          value: 'cifra_de_afaceri',
          sortable: true,
        },
        {
          text: 'Tara',
          value: 'country',
          sortable: false,
        },
        {
          text: 'Profil Activitate',
          value: 'activity_profile',
          sortable: false,
          width: "200px"
        },
        {
          text: 'User Details',
          value: 'user_details',
          sortable: false,
          width: "200px"
        },
        {
          text: 'Comments',
          value: 'comments',
          sortable: false,
          width: "200px"
        },
        {
          text: 'Ce Ar Trebui...',
          value: 'ce_ar_trebui',
          sortable: false,
          width: "200px"
        },
        {
          text: 'agent_id',
          value: 'agent_id',
          sortable: false,
          align: 'right'
        },
        {
          text: '',
          value: 'actions',
          width: '150',
          sortable: false,
          align: 'right'
        }
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems() {
      return this.$store.getters['oldResellers/paginatedData']
    },
    currentPage() {
      return this.$store.getters['oldResellers/currentPage']
    },
    sortParam() {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    openAddNew() {
      this.$store.dispatch('sidebar/setCurrentActiveSidebarComponent', {
        component: 'add-reseller',
        title: 'Adauga un cont de distribuitor'
      })
    },
    changePage(newPage) {
      this.$store.dispatch('oldResellers/changePage', newPage)
    },
    updateSortField(field) {
      this.sortFiled = field
    },
    updateSortType(isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter(searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('oldResellers/deleteFilter', filterName)
      } else {
        this.$store.dispatch('oldResellers/addFilter', {
          filterName,
          searchValue
        })
      }
    },
    loadItems() {
      this.$store.dispatch('oldResellers/loadItems')
    },
    deleteItem(item) {
      this.$store.dispatch('oldResellers/deleteItem', item.id)
    },
    resendConfirmationEmail(item) {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/oldResellers/${item.id}/resend-activation`)
          .then(() => {
            this.$vs.notify({
              title: 'Succes!',
              text: 'Emailul de confirmare a fost trimis cu succes!',
              color: 'success'
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Eroare!',
              text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          })
          .finally(() => {
            this.$store.dispatch('stopLoading')
          })
    },
    activateReseller(item) {
      this.$store.dispatch('startLoading')
      this.$http.patch(`clients/oldResellers/${item.id}/activate`)
          .then(() => {
            this.loadItems()
            this.$vs.notify({
              title: 'Succes!',
              text: 'Emailul de confirmare a fost trimis cu succes!',
              color: 'success'
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Eroare!',
              text: 'A aparut o eroare la trimiterea emailului. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          })
    },
  },
  watch: {
    sortParam(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('oldResellers/changeSort', newValue)
      }
    }
  },
  created() {
    this.loadItems()
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.v-card__text {
  overflow-x: scroll;
}

/deep/ .v-data-table__wrapper {
  overflow: initial;
}
</style>

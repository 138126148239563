<template>
  <div class="inline-block">
    <vs-tooltip text="Activeaza reseller si trimite email de confirmare!">
      <v-btn color="success" class="primary-color" icon x-small @click="activate">
        <v-icon>fal fa-save</v-icon>
      </v-btn>
    </vs-tooltip>
  </div>
</template>

<script>
export default {
  name: "ActivateButton",
  props: {
    resellerId: {
      required: true
    }
  },
  methods: {
    activate() {
      this.$store.dispatch('startLoading')
      this.$http.post(`/old-customers/${this.resellerId}`)
          .then(() => {
            this.$store.dispatch('oldResellers/loadItems')
          })
          .catch(({error}) => {
            console.error(error)
            this.$store.dispatch('stopLoading')
          })
    }
  }
}
</script>
